import { createApi } from "@reduxjs/toolkit/query/react";
import {
  TREECODE_API,
  CLIENTS_API,
  Branches_API,
  PRODUCTS_API,
  AREAS_API,
  CITIES_API,
  COUNTRIES_API,
  STATES_API,
  PRODUCT_CATEGORIES_API,
  DASHBOARD_API,
  ORGANIZATION_API,
  PAYMENT_REQUESTS_API,
  ORDER_TYPES_API
} from "../constants";
import { reAuthBaseQuery } from "./baseQuery";
import downloadHandler from "./downloadHandler";

export const treecodeApi = createApi({
  reducerPath: "treecodeApi",

  baseQuery: reAuthBaseQuery,
  tagTypes: ["Treecode", "Clients", "Branches", "Products", "Areas", "Cities", "Countries", "States", "ProductCategories", "Summary", "Organization", "Permissions", "PaymentRequests", "Tiers"],
  endpoints: (builder) => ({

    // Clients
    getClients: builder.mutation({
      query: () => ({
        url: CLIENTS_API.ROOT + "/",
        method: "GET"
      }),
      invalidatesTags: ["Clients"]
    }),
    getClient: builder.mutation({
      query: (clientId) => ({
        url: `${CLIENTS_API.ROOT}/${clientId}`,
        method: "GET"
      }),
      providesTags: ["Clients"]
    }),
    createClient: builder.mutation({
      query: (body) => ({
        url: CLIENTS_API.ROOT + "/",
        method: "POST",
        body
      }),
      invalidatesTags: ["Clients"]
    }),
    updateClient: builder.mutation({
      query: ({ clientId, ...body }) => ({
        url: `${CLIENTS_API.ROOT}/${clientId}`,
        method: "PUT",
        body
      }),
      invalidatesTags: ["Clients"]
    }),
    deleteClient: builder.mutation({
      query: (clientId) => ({
        url: `${CLIENTS_API.ROOT}/${clientId}`,
        method: "DELETE"
      }),
      invalidatesTags: ["Clients"]
    }),
    filterClients: builder.mutation({
      query: ({ seller, page = 1, limit = 10, ...queryParameters }) => ({
        url: CLIENTS_API.FILTER,
        method: "GET",
        params: {
          page,
          page_size: limit,
          seller,
          ...queryParameters
        }
      }),
      invalidatesTags: ["Clients"]
    }),
    getClientAddress: builder.mutation({
      query: (addressId) => ({
        url: `${CLIENTS_API.CLIENT_ADDRESS}/${addressId}`,
        method: "GET"
      }),
      providesTags: ["Clients"]
    }),
    updateClientAddress: builder.mutation({
      query: ({ addressId, ...body }) => ({
        url: `${CLIENTS_API.CLIENT_ADDRESS}/${addressId}`,
        method: "PUT",
        body
      }),
      invalidatesTags: ["Clients"]
    }),
    createClientAddress: builder.mutation({
      query: (body) => ({
        url: CLIENTS_API.CLIENT_ADDRESS + "/",
        method: "POST",
        body
      }),
      invalidatesTags: ["Clients"]
    }),

    // Branches
    getBranches: builder.mutation({
      query: ({ page = 1, limit = 10, ...queryParameters }) => ({
        url: Branches_API.ROOT + "/",
        method: "GET",
        params: {
          page,
          page_size: limit,
          ...queryParameters
        }
      }),
      invalidatesTags: ["Branches"]
    }),
    getBranch: builder.mutation({
      query: (branchId) => ({
        url: `${Branches_API.ROOT}/${branchId}`,
        method: "GET"
      }),
      providesTags: ["Branches"]
    }),
    createBranch: builder.mutation({
      query: (body) => ({
        url: Branches_API.ROOT + "/",
        method: "POST",
        body
      }),
      invalidatesTags: ["Branches"]
    }),
    updateBranch: builder.mutation({
      query: ({ branchId, ...body }) => ({
        url: `${Branches_API.ROOT}/${branchId}`,
        method: "PUT",
        body
      }),
      invalidatesTags: ["Branches"]
    }),
    deleteBranch: builder.mutation({
      query: (branchId) => ({
        url: `${Branches_API.ROOT}/${branchId}`,
        method: "DELETE"
      }),
      invalidatesTags: ["Branches"]
    }),

    // Products
    getProducts: builder.mutation({
      query: () => ({
        url: PRODUCTS_API.ROOT + "/",
        method: "GET"
      }),
      invalidatesTags: ["Products"]
    }),
    getProduct: builder.mutation({
      query: (productId) => ({
        url: `${PRODUCTS_API.ROOT}/${productId}`,
        method: "GET"
      }),
      providesTags: ["Products"]
    }),
    createProduct: builder.mutation({
      query: (body) => ({
        url: PRODUCTS_API.ROOT + "/",
        method: "POST",
        body
      }),
      invalidatesTags: ["Products"]
    }),
    updateProduct: builder.mutation({
      query: ({ productId, ...body }) => ({
        url: `${PRODUCTS_API.ROOT}/${productId}`,
        method: "PUT",
        body
      }),
      invalidatesTags: ["Products"]
    }),
    deleteProduct: builder.mutation({
      query: (productId) => ({
        url: `${PRODUCTS_API.ROOT}/${productId}`,
        method: "DELETE"
      }),
      invalidatesTags: ["Products"]
    }),
    filterProducts: builder.mutation({
      query: ({ seller, page = 1, limit = 10, ...queryParameters }) => ({
        url: PRODUCTS_API.FILTER,
        method: "GET",
        params: {
          page,
          page_size: limit,
          seller,
          ...queryParameters
        }
      }),
      invalidatesTags: ["Products"]
    }),

    // Areas
    getAreas: builder.mutation({
      query: () => ({
        url: AREAS_API.ROOT + "/",
        method: "GET"
      }),
      invalidatesTags: ["Areas"]
    }),
    getArea: builder.mutation({
      query: (areaId) => ({
        url: `${AREAS_API.ROOT}/${areaId}`,
        method: "GET"
      }),
      providesTags: ["Areas"]
    }),
    createArea: builder.mutation({
      query: (body) => ({
        url: AREAS_API.ROOT + "/",
        method: "POST",
        body
      }),
      invalidatesTags: ["Areas"]
    }),
    updateArea: builder.mutation({
      query: ({ areaId, ...body }) => ({
        url: `${AREAS_API.ROOT}/${areaId}`,
        method: "PUT",
        body
      }),
      invalidatesTags: ["Areas"]
    }),
    deleteArea: builder.mutation({
      query: (areaId) => ({
        url: `${AREAS_API.ROOT}/${areaId}`,
        method: "DELETE"
      }),
      invalidatesTags: ["Areas"]
    }),
    filterAreas: builder.mutation({
      query: ({ page = 1, limit = 10, ...queryParameters }) => ({
        url: AREAS_API.FILTER,
        method: "GET",
        params: {
          page,
          page_size: limit,
          ...queryParameters
        }
      }),
      invalidatesTags: ["Areas"]
    }),

    // Cities
    getCities: builder.mutation({
      query: ({ page = 1, limit = 10, ...queryParameters }) => ({
        url: CITIES_API.ROOT + "/",
        method: "GET",
        params: {
          page,
          page_size: limit,
          ...queryParameters
        }
      }),
      invalidatesTags: ["Cities"]
    }),
    getCity: builder.mutation({
      query: (cityId) => ({
        url: `${CITIES_API.ROOT}/${cityId}`,
        method: "GET"
      }),
      providesTags: ["Cities"]
    }),
    createCity: builder.mutation({
      query: (body) => ({
        url: CITIES_API.ROOT + "/",
        method: "POST",
        body
      }),
      invalidatesTags: ["Cities"]
    }),
    updateCity: builder.mutation({
      query: ({ cityId, ...body }) => ({
        url: `${CITIES_API.ROOT}/${cityId}`,
        method: "PUT",
        body
      }),
      invalidatesTags: ["Cities"]
    }),
    deleteCity: builder.mutation({
      query: (cityId) => ({
        url: `${CITIES_API.ROOT}/${cityId}`,
        method: "DELETE"
      }),
      invalidatesTags: ["Cities"]
    }),
    filterCities: builder.mutation({
      query: ({ page = 1, limit = 10, ...queryParameters }) => ({
        url: CITIES_API.FILTER,
        method: "GET",
        params: {
          page,
          page_size: limit,
          ...queryParameters
        }
      }),
      invalidatesTags: ["Cities"]
    }),

    // Countries
    getCountries: builder.mutation({
      query: () => ({
        url: COUNTRIES_API.ROOT + "/",
        method: "GET"
      }),
      invalidatesTags: ["Countries"]
    }),
    getCountry: builder.mutation({
      query: (countryId) => ({
        url: `${COUNTRIES_API.ROOT}/${countryId}`,
        method: "GET"
      }),
      providesTags: ["Countries"]
    }),
    createCountry: builder.mutation({
      query: (body) => ({
        url: COUNTRIES_API.ROOT + "/",
        method: "POST",
        body
      }),
      invalidatesTags: ["Countries"]
    }),
    updateCountry: builder.mutation({
      query: ({ countryId, ...body }) => ({
        url: `${COUNTRIES_API.ROOT}/${countryId}`,
        method: "PUT",
        body
      }),
      invalidatesTags: ["Countries"]
    }),
    deleteCountry: builder.mutation({
      query: (countryId) => ({
        url: `${COUNTRIES_API.ROOT}/${countryId}`,
        method: "DELETE"
      }),
      invalidatesTags: ["Countries"]
    }),

    // States
    getStates: builder.mutation({
      query: () => ({
        url: STATES_API.ROOT + "/",
        method: "GET",
        params: {
          page_size: 1000
        }
      }),
      invalidatesTags: ["States"]
    }),
    filterStates: builder.mutation({
      query: ({ page = 1, limit = 10, ...queryParameters }) => ({
        url: STATES_API.FILTER,
        method: "GET",
        params: {
          page,
          page_size: limit,
          ...queryParameters
        }
      }),
      invalidatesTags: ["States"]
    }),
    getState: builder.mutation({
      query: (stateId) => ({
        url: `${STATES_API.ROOT}/${stateId}`,
        method: "GET"
      }),
      providesTags: ["States"]
    }),
    updateState: builder.mutation({
      query: ({ stateId, ...body }) => ({
        url: `${STATES_API.ROOT}/${stateId}`,
        method: "PUT",
        body
      }),
      invalidatesTags: ["States"]
    }),

    // Product Categories
    getProductCategories: builder.mutation({
      query: ({ page = 1, limit = 10, ...queryParameters }) => ({
        url: PRODUCT_CATEGORIES_API.ROOT + "/",
        method: "GET",
        params: {
          page,
          page_size: limit,
          ...queryParameters
        }
      }),
      invalidatesTags: ["ProductCategories"]
    }),
    getProductCategory: builder.mutation({
      query: (productCategoryId) => ({
        url: `${PRODUCT_CATEGORIES_API.ROOT}/${productCategoryId}`,
        method: "GET"
      }),
      providesTags: ["ProductCategories"]
    }),
    createProductCategory: builder.mutation({
      query: (body) => ({
        url: PRODUCT_CATEGORIES_API.ROOT + "/",
        method: "POST",
        body
      }),
      invalidatesTags: ["ProductCategories"]
    }),
    updateProductCategory: builder.mutation({
      query: ({ productCategoryId, ...body }) => ({
        url: `${PRODUCT_CATEGORIES_API.ROOT}/${productCategoryId}`,
        method: "PUT",
        body
      }),
      invalidatesTags: ["ProductCategories"]
    }),
    deleteProductCategory: builder.mutation({
      query: (productCategoryId) => ({
        url: `${PRODUCT_CATEGORIES_API.ROOT}/${productCategoryId}`,
        method: "DELETE"
      }),
      invalidatesTags: ["ProductCategories"]
    }),
    filterProductCategories: builder.mutation({
      query: ({ page = 1, limit = 10, ...queryParameters }) => ({
        url: PRODUCT_CATEGORIES_API.FILTER,
        method: "GET",
        params: {
          page,
          page_size: limit,
          ...queryParameters
        }
      }),
      invalidatesTags: ["ProductCategories"]
    }),

    // Dashboard
    getSummary: builder.mutation({
      query: (summaryFilters) => ({
        url: DASHBOARD_API.SUMMARY,
        method: "GET",
        params: summaryFilters || {}
      }),
      invalidatesTags: ["Summary"]
    }),

    // Organization
    getOrganization: builder.mutation({
      query: () => ({
        url: ORGANIZATION_API.ROOT,
        method: "GET"
      }),
      invalidatesTags: ["Organization"]
    }),
    updateOrganization: builder.mutation({
      query: (body) => ({
        url: ORGANIZATION_API.ROOT,
        method: "PUT",
        body
      }),
      invalidatesTags: ["Organization"]
    }),
    updateOrganizationByForm: builder.mutation({
      query: (formData) => ({
        url: ORGANIZATION_API.ROOT,
        method: "PUT",
        body: formData,
        headers: {
          "Content-Type": "multipart/form-data"
        },
        formData: true
      }),
      invalidatesTags: ["Organization"]
    }),
    organizationChangeEmail: builder.mutation({
      query: (body) => ({
        url: ORGANIZATION_API.CHANGE_EMAIL_REQUEST,
        method: "POST",
        body
      }),
      invalidatesTags: ["Organization"]
    }),
    organizationVerifyEmail: builder.mutation({
      query: (body) => ({
        url: ORGANIZATION_API.CHANGE_EMAIL_VERIFY,
        method: "POST",
        body
      }),
      invalidatesTags: ["Organization"]
    }),

    // Permissions
    getPermissions: builder.mutation({
      query: (queryParameters) => ({
        url: TREECODE_API.GET_PERMISSIONS,
        method: "GET",
        params: queryParameters
      }),
      invalidatesTags: ["Permissions"]
    }),
    addPermissionToUser: builder.mutation({
      query: (body) => ({
        url: TREECODE_API.ADD_PERMISSION,
        method: "POST",
        body
      }),
      invalidatesTags: ["Permissions"]
    }),
    removePermissionFromUser: builder.mutation({
      query: (body) => ({
        url: TREECODE_API.REMOVE_PERMISSION,
        method: "POST",
        body
      }),
      invalidatesTags: ["Permissions"]
    }),

    // Payment Requests
    getPaymentRequests: builder.mutation({
      query: ({ page = 1, limit = 10, ...queryParameters }) => ({
        url: PAYMENT_REQUESTS_API.ROOT + "/",
        method: "GET",
        params: {
          page,
          page_size: limit,
          ...queryParameters
        }
      }),
      invalidatesTags: ["PaymentRequests"]
    }),
    getPaymentRequest: builder.mutation({
      query: (paymentRequestId) => ({
        url: `${PAYMENT_REQUESTS_API.ROOT}/${paymentRequestId}`,
        method: "GET"
      }),
      providesTags: ["PaymentRequests"]
    }),
    createPaymentRequestByForm: builder.mutation({
      query: (formData) => ({
        url: PAYMENT_REQUESTS_API.ROOT + "/",
        method: "POST",
        body: formData,
        headers: {
          "Content-Type": "multipart/form-data"
        },
        formData: true
      }),
      invalidatesTags: ["PaymentRequests"]
    }),
    createPaymentRequest: builder.mutation({
      query: (body) => ({
        url: PAYMENT_REQUESTS_API.ROOT + "/",
        method: "POST",
        body
      }),
      invalidatesTags: ["PaymentRequests"]
    }),
    filterPaymentRequests: builder.mutation({
      query: ({ page = 1, limit = 10, ...queryParameters }) => ({
        url: PAYMENT_REQUESTS_API.FILTER,
        method: "GET",
        params: {
          page,
          page_size: limit,
          ...queryParameters
        }
      }),
      invalidatesTags: ["PaymentRequests"]
    }),
    acceptPaymentRequestByForm: builder.mutation({
      query: (paymentRequestId, formData) => ({
        url: `${PAYMENT_REQUESTS_API.ACCEPT}/${paymentRequestId}`,
        method: "POST",
        body: formData,
        headers: {
          "Content-Type": "multipart/form-data"
        },
        formData: true
      }),
      invalidatesTags: ["PaymentRequests"]
    }),
    rejectPaymentRequest: builder.mutation({
      query: (id) => ({
        url: `${PAYMENT_REQUESTS_API.ACCEPT}/${id}`,
        method: "POST",
        body: { verdict: "False" }
      }),
      invalidatesTags: ["PaymentRequests"]
    }),
    acceptPaymentRequest: builder.mutation({
      query: (id) => ({
        url: `${PAYMENT_REQUESTS_API.ACCEPT}/${id}`,
        method: "POST",
        body: { verdict: "True" }
      }),
      invalidatesTags: ["PaymentRequests"]
    }),
    paymentSummary: builder.mutation({
      query: (queryParameters) => ({
        url: PAYMENT_REQUESTS_API.SUMMARY,
        method: "GET",
        params: queryParameters
      }),
      invalidatesTags: ["PaymentRequests"]
    }),
    addPaymentRequestProof: builder.mutation({
      query: (paymentRequestId, formData) => ({
        url: `${PAYMENT_REQUESTS_API.PROOF}/${paymentRequestId}`,
        method: "POST",
        body: formData,
        headers: {
          "Content-Type": "multipart/form-data"
        },
        formData: true
      }),
      invalidatesTags: ["PaymentRequests"]
    }),
    sellerConfirmPaymentRequest: builder.mutation({
      query: ({ paymentRequestId, ...body }) => ({
        url: `${PAYMENT_REQUESTS_API.SELLER_CONFIRM}/${paymentRequestId}`,
        method: "POST",
        body
      }),
      invalidatesTags: ["PaymentRequests"]
    }),

    // Tiers
    getTiers: builder.mutation({
      query: () => ({
        url: TREECODE_API.TIERS + "/",
        method: "GET",
        params: {
          page_size: 1000
        }
      }),
      invalidatesTags: ["Tiers"]
    }),
    getTier: builder.mutation({
      query: (tierId) => ({
        url: `${TREECODE_API.TIERS}/${tierId}`,
        method: "GET"
      }),
      providesTags: ["Tiers"]
    }),
    updateTier: builder.mutation({
      query: ({ tierId, ...body }) => ({
        url: `${TREECODE_API.TIERS}/${tierId}`,
        method: "PUT",
        body
      }),
      invalidatesTags: ["Tiers"]
    }),
    downloadLabel: builder.mutation({
      query: (queryParameters) => ({
        url: TREECODE_API.DOWNLOAD_LABEL,
        method: "GET",
        params: queryParameters,
        responseHandler: async (response) => await downloadHandler(response),
        cache: "no-cache"
      })
    }),
    planConsumption: builder.mutation({
      query: (queryParameters) => ({
        url: TREECODE_API.PLAN_CONSUMPTION,
        method: "GET",
        params: queryParameters
      })
    }),
    changeAwbType: builder.mutation({
      query: (body) => ({
        url: TREECODE_API.AWB_TYPE,
        method: "POST",
        body
      }),
      invalidatesTags: ["AwbType"]
    }),
    courierWalletActivateSwitch: builder.mutation({
      query: () => ({
        url: TREECODE_API.COURIER_WALLET_SWITCH,
        method: "POST"
      }),
      invalidatesTags: ["CourierWallet"]
    }),
    cancellationReasonRequiredSwitch: builder.mutation({
      query: () => ({
        url: TREECODE_API.CANCELLATION_REASON_REQUIRED_SWITCH,
        method: "POST"
      })
    }),
    returnReasonRequiredSwitch: builder.mutation({
      query: () => ({
        url: TREECODE_API.RETURN_REASON_REQUIRED_SWITCH,
        method: "POST"
      })
    }),
    returnReasonActivateSwitch: builder.mutation({
      query: () => ({
        url: TREECODE_API.RETURN_REASON_ACTIVATE_SWITCH,
        method: "POST"
      })
    }),
    getOrderTypes: builder.mutation({
      query: ({ page = 1, limit = 10, ...queryParameters }) => ({
        url: ORDER_TYPES_API.ROOT + "/",
        method: "GET",
        params: {
          page,
          page_size: limit,
          ...queryParameters
        }
      }),
      invalidatesTags: ["OrderTypes"]
    }),
    getOrderType: builder.mutation({
      query: (orderTypeId) => ({
        url: `${ORDER_TYPES_API.ROOT}/${orderTypeId}/`,
        method: "GET"
      }),
      providesTags: ["OrderTypes"]
    }),
    createOrderType: builder.mutation({
      query: (body) => ({
        url: ORDER_TYPES_API.ROOT + "/",
        method: "POST",
        body
      }),
      invalidatesTags: ["OrderTypes"]
    }),
    updateOrderType: builder.mutation({
      query: ({ orderTypeId, ...body }) => ({
        url: `${ORDER_TYPES_API.ROOT}/${orderTypeId}/`,
        method: "PATCH",
        body
      }),
      invalidatesTags: ["OrderTypes"]
    }),
    deleteOrderType: builder.mutation({
      query: (orderTypeId) => ({
        url: `${ORDER_TYPES_API.ROOT}/${orderTypeId}/`,
        method: "DELETE"
      }),
      invalidatesTags: ["OrderTypes"]
    }),
    changeOrderType: builder.mutation({
      query: (body) => ({
        url: ORDER_TYPES_API.CHANGE_ORDER_TYPE,
        method: "POST",
        body
      }),
      invalidatesTags: ["OrderTypes"]
    })
  })
});

export const {
  useGetClientsMutation,
  useGetClientMutation,
  useCreateClientMutation,
  useUpdateClientMutation,
  useDeleteClientMutation,
  useFilterClientsMutation,
  useGetBranchesMutation,
  useGetBranchMutation,
  useCreateBranchMutation,
  useUpdateBranchMutation,
  useDeleteBranchMutation,
  useGetProductsMutation,
  useGetProductMutation,
  useCreateProductMutation,
  useUpdateProductMutation,
  useDeleteProductMutation,
  useFilterProductsMutation,
  useGetAreasMutation,
  useGetAreaMutation,
  useCreateAreaMutation,
  useUpdateAreaMutation,
  useDeleteAreaMutation,
  useFilterAreasMutation,
  useGetCitiesMutation,
  useGetCityMutation,
  useCreateCityMutation,
  useUpdateCityMutation,
  useDeleteCityMutation,
  useFilterCitiesMutation,
  useGetCountriesMutation,
  useGetCountryMutation,
  useCreateCountryMutation,
  useUpdateCountryMutation,
  useDeleteCountryMutation,
  useGetStatesMutation,
  useFilterStatesMutation,
  useGetStateMutation,
  useUpdateStateMutation,
  useGetProductCategoriesMutation,
  useGetProductCategoryMutation,
  useCreateProductCategoryMutation,
  useUpdateProductCategoryMutation,
  useDeleteProductCategoryMutation,
  useFilterProductCategoriesMutation,
  useGetSummaryMutation,
  useGetOrganizationMutation,
  useUpdateOrganizationMutation,
  useOrganizationChangeEmailMutation,
  useOrganizationVerifyEmailMutation,
  useGetPermissionsMutation,
  useAddPermissionToUserMutation,
  useRemovePermissionFromUserMutation,
  useGetPaymentRequestsMutation,
  useGetPaymentRequestMutation,
  useCreatePaymentRequestMutation,
  useCreatePaymentRequestByFormMutation,
  useFilterPaymentRequestsMutation,
  useAcceptPaymentRequestMutation,
  useAcceptPaymentRequestByFormMutation,
  useRejectPaymentRequestMutation,
  usePaymentSummaryMutation,
  useAddPaymentRequestProofMutation,
  useGetClientAddressMutation,
  useUpdateClientAddressMutation,
  useCreateClientAddressMutation,
  useGetTiersMutation,
  useGetTierMutation,
  useUpdateTierMutation,
  useSellerConfirmPaymentRequestMutation,
  useDownloadLabelMutation,
  useUpdateOrganizationByFormMutation,
  usePlanConsumptionMutation,
  useChangeAwbTypeMutation,
  useCourierWalletActivateSwitchMutation,
  useCancellationReasonRequiredSwitchMutation,
  useReturnReasonRequiredSwitchMutation,
  useReturnReasonActivateSwitchMutation,
  useGetOrderTypesMutation,
  useGetOrderTypeMutation,
  useCreateOrderTypeMutation,
  useUpdateOrderTypeMutation,
  useDeleteOrderTypeMutation,
  useChangeOrderTypeMutation
} = treecodeApi;
