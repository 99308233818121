import React from "react";
import {
  WalletIcon,
  PersonSegmentIcon,
  HomeIcon,
  ContractIcon,
  ChartCohortIcon,
  TransactionFeeDollarIcon,
  TransferInternalIcon,
  OrderFulfilledIcon,
  ProductIcon,
  KeyIcon,
  HashtagIcon,
  InventoryIcon,
  StoreIcon,
  DomainNewIcon,
  OrderDraftIcon
} from "@shopify/polaris-icons";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
// import { Inventory } from "@mui/icons-material";
import Dashboard from "../../features/common/dashboard/ui";
import CreateAccount from "../../features/admin/accounts/create/createAccount";
import BulkAccount from "../../components/pages/BulkAccount";
import CreateRunsheet from "../../components/layout/createRunsheet";
import AllInternals from "../../components/layout/AllInternals";
import EditRunsheetPriorities from "../../components/layout/EditRunsheetPriorities";
import AccountsTabs from "../../components/layout/Accounts/AccountsTabs";
import ViewAssignedRunSheets from "../../components/pages/ViewAssignedRunSheets";
import CloseRunsheet from "../../components/pages/closeRunsheet";
import ActivityLogs from "../../components/pages/ActivityLogs";
import ArchivedRunsheets from "../../components/layout/archivedRunsheets";
import AddTicketsToRunSheet from "../../components/layout/AddTicketsToRunSheet/AddTicketsToRunSheet";
import ViewArchivedRunsheetsOrders from "../../components/pages/viewArchivedRunsheetsOrders";
import Payments from "../../components/pages/payments";
import PaymentSuccess from "../../components/pages/paymentSuccess";
import PaymentFail from "../../components/pages/paymentFail";
import Billing from "../../components/pages/settings/billing";
import ArchivedUsers from "../../components/layout/Accounts/ArchivedUsers";
import AccountInfo from "../../components/pages/AccountInfo";
import MoveOrders from "../../components/pages/MoveOrders/MoveOrders";
import PermissionsTabs from "../../components/pages/settings/permissions/permissionsTabs";
import StoreReportsPage from "../../components/pages/Reports/StoreReportsPage";
import BranchReportsPage from "../../components/pages/Reports/BranchReportsPage";
import ReportsTypeSelection from "../../components/pages/Reports/ReportsTypeSelection";
import SellersWallets from "../../components/pages/wallets/sellers";
import SellersOrders from "../../components/pages/wallets/orders";
import SellerOrders from "../../components/pages/wallets/sellerWallet/sellerOrders";
import Settelment from "../../components/pages/wallets/settelment";
import RecievedRequests from "../../components/pages/wallets/recievedRequests";
import SellersRecievedRequests from "../../components/pages/wallets/sellerWallet/sellersRecievedRequests";
import SellerSettelment from "../../components/pages/wallets/sellerWallet/sellerSettelment";
import PaidRequests from "../../components/pages/wallets/paidRequests";
import SellersPaidRequests from "../../components/pages/wallets/sellerWallet/sellersPaidRequests";
import ForgetPassword from "../../components/Login-screens/ForgetPassword";
import Registeration from "../../components/pages/registeration";
import RegisterationAr from "../../components/pages/registerationAr";
import ConfirmationCode from "../../components/Login-screens/ConfirmationCode";
import ResetPassword from "../../components/Login-screens/ResetPassword";
import EditInternal from "../../components/layout/EditInternal";
import ReassignInternal from "../../components/layout/ReassignInternal";
import Login from "../../components/Login-screens/Login";
import CreateProduct from "../../features/seller/products/ui/CreateProduct";
import AllProducts from "../../features/seller/products/ui/AllProducts";
import AllTiersPage from "../../features/admin/tiers/ui/all-tiers/all-tiers";
import EditTier from "../../features/admin/tiers/ui/edit-tier/edit-tier";
import SellerAccountInfo from "../../components/pages/seller-account-info/seller-account-info";
import OrderUnAuth from "../../components/pages/OrderUnAuth";
import ThirdPartyInfo from "../../components/pages/ThirdPartyInfo";
import ThirdPartyKeys from "../../components/pages/ThirdPartyKeys";
import Webhooks from "../../components/pages/Webhooks";
import AllWarehouses from "../../components/pages/WareHouses";
import WarhouseDetails from "../../components/pages/WareHouses/Warehouse";
import WarehouseLocations from "../../components/pages/WareHouses/Locations";
import WarehouseLocationDetails from "../../components/pages/WareHouses/Location";
import WarehouseLocationRules from "../../components/pages/WareHouses/Rules";
import WarehouseLocationRuleDetails from "../../components/pages/WareHouses/Rule";
import InventoryProductsTable from "../../features/common/Inventory/InventoryOrders";
import AddInventoryItem from "../../features/common/Inventory/AddInventoryItem";
import AllCategories from "../../components/pages/ProductCategories";
import NoSubscriptionDashboard from "../../features/common/dashboard/ui/NoSubscriptionDashboard";
import { setDashboardType } from "../../stateManagement/slices/dashboardSlice";
import InventoryDashboard from "../../features/common/dashboard/ui/InventoryDashboard";
// import AdminCreateInventoryOrder from "../../features/admin/Inventory/AcminCreateInventory";
import CreateNewInventoryOrder from "../../features/admin/Inventory/CreateNewInventoryOrder";
import InventoryOrders from "../../components/pages/InventoryOrders";
import { INVENTORY_ORDER_STATUS } from "../../utils/beDict";
// import SellerCreateInventory from "../../features/seller/Inventory/SellerCreateInventory";
import CreateNewInventoryOrderSeller from "../../features/seller/Inventory/CreateInventory_seller";
import AcceptsellerCreatedOrder from "../../components/pages/InventoryOrders/AcceptsellerCreatedOrder";
import Plugins from "../../features/admin/plugins/Plugins";
import AllSmsTemplates from "../../components/pages/SmsTemplates";
import SmsTemplateDetails from "../../components/pages/SmsTemplates/SmsTemplate";
import { useCourierWalletAvailable, useInventoryActive } from "../../stateManagement/slices/userSlice";
import PricingPage from "../../components/pages/OurPlans";
import PaymentResult from "../../components/pages/Payments/PaymentResult";
import CourierWallets from "../../components/pages/CourierWallets";
import useSettingsRoutes from "./settings";
import useOrdersRoutes, { useAdminOrdersRoute, useSellerOrdersRoute } from "./orders";

export const useNoSubscriptionRoutes = () => {
  const { t } = useTranslation();
  const routes = {
    noSubscriptionDashboard: {
      meta: {
        title: t("sideMenu.dashboard"),
        icon: HomeIcon
      },
      "/dashboard": {
        element: NoSubscriptionDashboard,
        title: t("sideMenu.dashboard"),
        icon: HomeIcon
      }
    },
    plans: {
      meta: {
        title: t("Our Plans"),
        icon: TransactionFeeDollarIcon
      },
      "/plans": {
        element: PricingPage,
        title: t("Our Plans"),
        icon: TransactionFeeDollarIcon
      }
    },

    validation: {
      "/validate-transaction/:transactionId": {
        element: PaymentResult
      }
    },

    unAuthed: {
      "/third_party_login": {
        element: Login
      },
      "/forget_password": {
        element: ForgetPassword
      },
      "/registeration": {
        element: Registeration
      },
      "/registerationar": {
        element: RegisterationAr
      },
      "/confirm": {
        element: ConfirmationCode
      },
      "/reset_password": {
        element: ResetPassword
      },
      "/track/:waybill": {
        element: OrderUnAuth
      }
    }
  };
  return routes;
};

export const useInventoryRoutes = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const routes = {
    adminDashboard: {
      meta: {
        title: t("sideMenu.adminDashboard"),
        icon: StoreIcon
      },
      "/dashboard": {
        element: Dashboard,
        title: t("sideMenu.adminDashboard"),
        icon: StoreIcon,
        sidebarActionMethod: () =>
          dispatch(setDashboardType("default"))
      }
    },
    home: {
      meta: {
        title: t("sideMenu.dashboard"),
        icon: HomeIcon
      },
      "/inventory_dashboard": {
        element: InventoryDashboard,
        title: t("sideMenu.dashboard"),
        icon: HomeIcon,
        sidebarActionMethod: () =>
          dispatch(setDashboardType("inventory"))
      }
    },
    inventoryOrders: {
      meta: {
        title: t("InventoryOrders"),
        icon: OrderFulfilledIcon
      },
      "/create_inventory_order": {
        element: CreateNewInventoryOrder,
        title: t("Create Inventory Order"),
        rules: [
          "Can view warehouse",
          "Can add warehouse",
          "Can change warehouse",
          "Can delete warehouse"
        ],
        icon: OrderFulfilledIcon
      },
      "/inventory_orders/approved": {
        element: InventoryOrders,
        title: t("Approved Orders"),
        rules: [
          "Can view warehouse",
          "Can add warehouse",
          "Can change warehouse",
          "Can delete warehouse"
        ],
        icon: OrderFulfilledIcon,
        navigateProps: { status: INVENTORY_ORDER_STATUS.APPROVED }
      },
      "/inventory_orders/completed": {
        element: InventoryOrders,
        title: t("Completed Orders"),
        rules: [
          "Can view warehouse",
          "Can add warehouse",
          "Can change warehouse",
          "Can delete warehouse"
        ],
        icon: OrderFulfilledIcon,
        navigateProps: { status: INVENTORY_ORDER_STATUS.COMPLETED }
      },
      "/inventory_orders/rejected": {
        element: InventoryOrders,
        title: t("Rejected Orders"),
        rules: [
          "Can view warehouse",
          "Can add warehouse",
          "Can change warehouse",
          "Can delete warehouse"
        ],
        icon: OrderFulfilledIcon,
        navigateProps: { status: INVENTORY_ORDER_STATUS.REJECTED }
      },
      "/accept_inventory_order/:id": {
        element: AcceptsellerCreatedOrder,
        title: t("Accept Inventory Order"),
        rules: [
          "Can view warehouse",
          "Can add warehouse",
          "Can change warehouse",
          "Can delete warehouse"
        ]
      }
    },
    productCategories: {
      meta: {
        title: t("sideMenu.productCategories"),
        icon: ProductIcon
      },
      "/product_categories": {
        element: AllCategories,
        icon: ProductIcon,
        title: t("sideMenu.productCategories"),
        rules: [
          "Can view category",
          "Can add category",
          "Can change category",
          "Can delete category"
        ]
      }
    },
    InventoryItems: {
      meta: {
        title: t("sideMenu.products"),
        icon: ProductIcon
      },
      "/inventory_items": {
        element: InventoryProductsTable,
        title: t("sideMenu.products"),
        icon: ProductIcon,
        rules: [
          "Can view product",
          "Can add product",
          "Can change product",
          "Can delete product"
        ]
      },
      "/new_inv_item": {
        element: AddInventoryItem,
        title: t("sideMenu.products"),
        icon: ProductIcon
      },
      "/inv_item/:id": {
        element: AddInventoryItem,
        title: t("sideMenu.products"),
        icon: ProductIcon
      }
    },
    warehouses: {
      meta: {
        title: t("sideMenu.warehouses"),
        icon: InventoryIcon
      },
      "/warehouses": {
        element: AllWarehouses,
        icon: InventoryIcon,
        title: t("sideMenu.allWarehouses"),
        rules: ["Can view warehouse"]
      },
      "/warehouse/:warehouseId": {
        element: WarhouseDetails,
        rules: [
          "Can view warehouse",
          "Can add warehouse",
          "Can change warehouse",
          "Can delete warehouse"
        ]
      },
      "/warehouse/:warehouseId/locations": {
        element: WarehouseLocations,
        rules: [
          "Can view warehouse",
          "Can add warehouse",
          "Can change warehouse",
          "Can delete warehouse"
        ]
      },
      "/warehouse/:warehouseId/locations/:locationId": {
        element: WarehouseLocationDetails,
        rules: [
          "Can view warehouse",
          "Can add warehouse",
          "Can change warehouse",
          "Can delete warehouse"
        ]
      },
      "/warehouse/:warehouseId/rules": {
        element: WarehouseLocationRules,
        rules: [
          "Can view warehouse",
          "Can add warehouse",
          "Can change warehouse",
          "Can delete warehouse"
        ]
      },
      "/warehouse/:warehouseId/rules/:ruleId": {
        element: WarehouseLocationRuleDetails,
        rules: [
          "Can view warehouse",
          "Can add warehouse",
          "Can change warehouse",
          "Can delete warehouse"
        ]
      }
    },
    unAuthed: {
      "/third_party_login": {
        element: Login
      },
      "/forget_password": {
        element: ForgetPassword
      },
      "/registeration": {
        element: Registeration
      },
      "/registerationar": {
        element: RegisterationAr
      },
      "/confirm": {
        element: ConfirmationCode
      },
      "/reset_password": {
        element: ResetPassword
      },
      "/track/:waybill": {
        element: OrderUnAuth
      }
    }
  };
  return routes;
};

export function useThirdPartyRoutes () {
  const { t } = useTranslation();
  return {
    common: {
      "/dashboard": {
        element: Dashboard,
        isOpen: true,
        title: t("sideMenu.dashboard"),
        icon: HomeIcon
      },
      "/api_keys": {
        element: ThirdPartyKeys,
        title: t("sideMenu.apiKeys"),
        icon: KeyIcon
      },
      "/webhooks": {
        element: Webhooks,
        title: t("sideMenu.webhooks"),
        icon: HashtagIcon
      }
    },
    settings: useSettingsRoutes(),
    unAuthed: {
      "/third_party_login": {
        element: Login
      },
      "/forget_password": {
        element: ForgetPassword
      },
      "/registeration": {
        element: Registeration
      },
      "/registerationar": {
        element: RegisterationAr
      },
      "/confirm": {
        element: ConfirmationCode
      },
      "/reset_password": {
        element: ResetPassword
      },
      "/track/:waybill": {
        element: OrderUnAuth
      }
    }
  };
}

export function useAdminRoutes () {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const courierWalletAvailable = useCourierWalletAvailable();
  const inventoryActive = useInventoryActive();
  const routes = {
    common: {
      "/dashboard": {
        element: Dashboard,
        title: t("sideMenu.dashboard"),
        icon: HomeIcon
      }
    },
    accounts: {
      meta: {
        title: t("sideMenu.accounts"),
        icon: PersonSegmentIcon
      },
      "/create_account": {
        element: CreateAccount,
        title: t("sideMenu.createNewAcc"),
        rules: ["Can add user"]
      },
      "/create_bulk_accounts": {
        element: BulkAccount,
        title: t("sideMenu.createBulk"),
        rules: ["Can add user"]
      },
      "/all_accounts": {
        element: AccountsTabs,
        title: t("sideMenu.allAccounts"),
        rules: ["Can view user"]
      },
      "/archived_users": {
        element: ArchivedUsers,
        title: t("sideMenu.archived"),
        rules: ["Can view user"]
      },
      "/user_details/seller/:id": {
        element: SellerAccountInfo,
        rules: ["Can view user"]
      },
      "/user_details/thirdParty/:id": {
        element: ThirdPartyInfo,
        rules: ["Can view user"]
      },
      "/user_details/:type/:id": {
        element: AccountInfo,
        rules: ["Can view user"]
      },
      "/permissions": {
        element: PermissionsTabs,
        isOpen: true
      }
    },
    orders: { ...useOrdersRoutes(), ...useAdminOrdersRoute() },
    runsheets: {
      meta: {
        title: t("sideMenu.runsheets"),
        icon: ContractIcon
      },
      "/create_runsheet": {
        element: CreateRunsheet,
        title: t("sideMenu.createNewRunsheet"),
        rules: ["Can add runsheet"]
      },
      "/reassign_runsheet/:id": {
        element: () => <CreateRunsheet isReAssignedProp={true} />,
        rules: ["Can change runsheet"]
      },
      "/remove_runsheet_orders/:id": {
        element: () => <CreateRunsheet isReAssignedProp={true} removeOrders={true} />,
        rules: ["Can change runsheet"]
      },
      "/assigned_rs": {
        element: ViewAssignedRunSheets,
        title: t("sideMenu.assinedRunsheet"),
        rules: ["Can view runsheet"]
      },
      "/archived_runsheets": {
        element: ArchivedRunsheets,
        title: t("sideMenu.archived"),
        rules: ["Can view runsheet"]
      },
      "/edit_runsheet_priorities/:id/:type": {
        element: EditRunsheetPriorities,
        rules: ["Can change runsheet"]
      },
      "/add_to_runsheet/:id": {
        element: AddTicketsToRunSheet,
        rules: ["Can change runsheet"]
      },
      "/view_runsheet_orders": {
        element: ViewArchivedRunsheetsOrders,
        rules: ["Can view runsheet"]
      },
      "/closeRunsheet/:id": {
        element: CloseRunsheet,
        rules: ["Can change runsheet"]
      }
    },
    internalRunsheets: {
      meta: {
        title: t("sideMenu.internalRunSheet"),
        icon: TransferInternalIcon
      },
      "/move_orders": {
        element: MoveOrders,
        title: t("sideMenu.createInternal"),
        rules: ["Can add runsheet"]
      },
      "/all_internals": {
        element: AllInternals,
        title: t("sideMenu.allInternals"),
        rules: ["Can view runsheet"]
      },
      "/edit_internal/:id/:oper": {
        element: EditInternal,
        title: t("Edit Internal Runsheet"),
        rules: ["Can change runsheet"]
      },
      "/reassign_internal/:id": {
        element: ReassignInternal,
        title: t("Reassign Internal Runsheet"),
        rules: ["Can change runsheet"]
      }
    },
    tiers: {
      meta: {
        title: t("sideMenu.tiers"),
        icon: TransactionFeeDollarIcon
      },
      "/tiers": {
        element: AllTiersPage,
        title: t("sideMenu.tiers"),
        icon: TransactionFeeDollarIcon,
        rules: [
          "Can view tier",
          "Can add tier",
          "Can change tier",
          "Can delete tier"
        ]
      },
      "/tier/:id": {
        element: EditTier,
        rules: [
          "Can view tier",
          "Can add tier",
          "Can change tier",
          "Can delete tier"
        ]
      }
    },

    workModels: {
      meta: {
        icon: TransactionFeeDollarIcon,
        title: t("sideMenu.workModels")
      }
      // "/create_work_model": {
      //   element: CreateWorkModel,
      //   title: t("sideMenu.createWorkModel"),
      //   rules: [113]
      // },
      // "/all_work_models": {
      //   element: AllWorkModels,
      //   title: t("sideMenu.all_work_models"),
      //   rules: [116, 114, 115]
      // },
      // "/view_work_model/:id": {
      //   element: SingleWorkModel,
      //   rules: [116, 114, 115]
      // },
      // "/copy_work_model/:id": {
      //   element: SingleWorkModel,
      //   rules: [113, 116, 115]
      // },
      // "/edit_diemention_work_model/:id": {
      //   element: PricesStep,
      //   rules: [113, 116, 114, 115]
      // }
    },
    wallet: {
      meta: {
        title: t("sideMenu.wallet"),
        icon: WalletIcon
      },
      "/sellers_wallets": {
        element: SellersWallets,
        title: t("sideMenu.sellers"),
        rules: [
          "Can view wallet",
          "Can add wallet",
          "Can change wallet",
          "Can delete wallet"
        ]
      },
      "/pay_requests": {
        element: PaidRequests,
        title: t("sideMenu.requestsToPay"),
        rules: [
          "Can view wallet",
          "Can add wallet",
          "Can change wallet",
          "Can delete wallet"
        ]
      },
      "/recieved_requests": {
        element: RecievedRequests,
        title: t("sideMenu.requestsToRecieve"),
        rules: [
          "Can view wallet",
          "Can add wallet",
          "Can change wallet",
          "Can delete wallet"
        ]
      },
      "/payments": {
        element: Payments
      },
      "/payment_success": {
        element: PaymentSuccess
      },
      "/payment_fail": {
        element: PaymentFail
      },
      "/billing": {
        element: Billing
      },
      // "/price_lists": {
      //   element: PriceLists
      // },
      "/seller_orders": {
        element: SellerOrders,
        title: t("orders")
      },
      "/seller_pay": {
        element: SellersPaidRequests,
        title: t("sideMenu.requestsToPay")
      },
      "/seller_recieve": {
        element: SellersRecievedRequests,
        title: t("sideMenu.confirmReceive")
      },
      "/sellers_orders/:id": {
        element: SellersOrders
      },
      "/seller_settelment/:id": {
        element: SellerSettelment
      },
      "/settelment/:id": {
        element: Settelment
      }
    },
    reports: {
      "/select_reports": {
        element: ReportsTypeSelection,
        title: t("sideMenu.reports"),
        icon: ChartCohortIcon,
        rules: ["Can view report"]
      },
      "/branch_reports": {
        element: BranchReportsPage,
        rules: ["Can view report"]
      },
      "/store_reports": {
        element: StoreReportsPage,
        rules: ["Can view report"]
      }
    },
    plugins: {
      meta: {
        icon: DomainNewIcon,
        title: t("integrationApps")
      },
      "/plugins": {
        element: Plugins,
        icon: DomainNewIcon,
        title: t("integrationApps")
      }
    },

    smsTemplates: {
      meta: {
        title: t("sideMenu.smsTemplates"),
        icon: OrderDraftIcon
      },
      "/sms_templates": {
        element: AllSmsTemplates,
        title: t("sideMenu.smsTemplates"),
        icon: OrderDraftIcon,
        rules: []
      },
      "/sms_templates/:id": {
        element: SmsTemplateDetails,
        rules: []
      }
    },
    settings: useSettingsRoutes(),
    unused: {
      "/logs": {
        element: ActivityLogs
      }
    },
    unAuthed: {
      "/admin_forget_password": {
        element: ForgetPassword
      },
      "/registeration": {
        element: Registeration
      },
      "/registerationar": {
        element: RegisterationAr
      },
      "/admin_confirm": {
        element: ConfirmationCode
      },
      "/admin_reset_password": {
        element: ResetPassword
      },
      "/login": {
        element: Login
      },
      "/track/:waybill": {
        element: OrderUnAuth
      }
    }
  };
  const inventoryRoutes = useInventoryRoutes();
  if (inventoryActive) {
    routes.inventoryDashboard = {
      meta: {
        title: t("sideMenu.inventory"),
        icon: InventoryIcon
      },
      "/inventory_dashboard": {
        element: InventoryDashboard,
        title: t("sideMenu.inventoryDashboard"),
        icon: InventoryIcon,
        rules: ["Can view inventory"],
        sidebarActionMethod: () =>
          dispatch(setDashboardType("inventory"))
      }
    };
    Object.assign(routes, inventoryRoutes);
  }
  if (courierWalletAvailable) {
    routes.wallet["/courier_wallets"] = {
      element: CourierWallets,
      title: t("sideMenu.courierWallets"),
      rules: [
        "Can view wallet",
        "Can add wallet",
        "Can change wallet",
        "Can delete wallet"
      ]
    };
  }
  return routes;
}

export function useSellerRoutes (isThirdParty = false) {
  const { t } = useTranslation();
  const routes = {
    common: {
      "/dashboard": {
        element: Dashboard,
        isOpen: true,
        title: t("sideMenu.dashboard"),
        icon: HomeIcon
      }
    },
    products: {
      meta: {
        title: t("sideMenu.products"),
        icon: ProductIcon
      },
      "/add_product": {
        element: CreateProduct,
        title: t("sideMenu.createProduct")
      },
      "/products": {
        element: AllProducts,
        title: t("sideMenu.allProducts")
      }
    },
    orders: {
      ...useOrdersRoutes(),
      ...useSellerOrdersRoute(),
      meta: {
        title: t("orders"),
        icon: OrderFulfilledIcon
      }
    },
    inventory: {
      meta: {
        title: t("Inventory Orders"),
        icon: StoreIcon
      },
      "/create_inventory_order": {
        element: CreateNewInventoryOrderSeller,
        // element:SellerCreateInventory,
        title: t("Create Inventory Order")
      },
      "/pending_inventory_orders": {
        element: InventoryOrders,
        title: t("Pending Orders"),
        navigateProps: { status: INVENTORY_ORDER_STATUS.PENDING }
      },
      "/rejected_inventory_orders": {
        element: InventoryOrders,
        title: t("Rejected Orders"),
        navigateProps: { status: INVENTORY_ORDER_STATUS.REJECTED }
      },
      "/completed_inventory_orders": {
        element: InventoryOrders,
        title: t("Completed Orders"),
        navigateProps: { status: INVENTORY_ORDER_STATUS.COMPLETED }
      },
      "/approved_inventory_orders": {
        element: InventoryOrders,
        title: t("Approved Orders"),
        navigateProps: { status: INVENTORY_ORDER_STATUS.APPROVED }
      }
    },
    wallet: {
      meta: {
        title: t("sideMenu.wallet"),
        icon: WalletIcon
      },
      "/seller_orders": {
        element: SellerOrders,
        title: t("orders")
      },
      "/seller_pay": {
        element: SellersPaidRequests,
        title: t("sideMenu.requestsToPay")
      },
      "/seller_recieve": {
        element: SellersRecievedRequests,
        title: t("sideMenu.confirmReceive")
      },
      "/sellers_orders/:id": {
        element: SellersOrders
      },
      "/recieved_requests": {
        element: RecievedRequests
      },
      "/seller_settelment/:id": {
        element: SellerSettelment
      },
      "/sellers_wallets": {
        element: SellersWallets,
        title: t("sideMenu.sellers")
      },
      "/pay_requests": {
        element: PaidRequests,
        title: t("sideMenu.requestsToPay")
      },
      "/payments": {
        element: Payments
      },
      "/payment_success": {
        element: PaymentSuccess
      },
      "/payment_fail": {
        element: PaymentFail
      },
      "/billing": {
        element: Billing
      },
      // "/price_lists": {
      //   element: PriceLists
      // },
      "/settelment/:id": {
        element: Settelment
      }
    },
    settings: useSettingsRoutes(),
    unAuthed: {
      "/seller-login": {
        element: Login
      },
      "/forget_password": {
        element: ForgetPassword
      },
      "/registeration": {
        element: Registeration
      },
      "/registerationar": {
        element: RegisterationAr
      },
      "/confirm": {
        element: ConfirmationCode
      },
      "/reset_password": {
        element: ResetPassword
      },
      "/track/:waybill": {
        element: OrderUnAuth
      }
    }
  };
  if (isThirdParty) {
    routes.common = {
      ...routes.common,
      "/api_keys": {
        element: ThirdPartyKeys,
        title: t("sideMenu.apiKeys"),
        icon: KeyIcon
      },
      "/webhooks": {
        element: Webhooks,
        title: t("sideMenu.webhooks"),
        icon: HashtagIcon
      }
    };
  }
  return routes;
}
