const BaseUrl = `${import.meta.env.VITE_APP_BASE_URL.includes("localhost") ? "http" : "https"}://${window.location.hostname.split(".")[0]}.${import.meta.env.VITE_APP_BASE_URL}`;
// const BaseUrl = "https://treecode.loca.lt";
const BasePublicUrl = `${import.meta.env.VITE_APP_BASE_URL.includes("localhost") ? "http" : "https"}://${import.meta.env.VITE_PUBLIC_SUBDOMAIN
  ? import.meta.env.VITE_PUBLIC_SUBDOMAIN + "."
: ""
}${import.meta.env.VITE_APP_BASE_URL}`;

const LOGIN = "LOGIN";
const CLEAR_AUTH = "CLEAR_AUTH";

const AUTH_API = {
  LOGIN: "/auth/jwt/create",
  REFRESH: "/auth/jwt/refresh/",
  REGISTER: "/client-create/",
  UPDATE_PASSWORD: "/update_password"
};

const ACCOUNTS_API = {
  ROOT: "/OrgEmployee",
  FILTER: "/FilterOrgEmployee/",
  FILTER_COURIERS: "/FilterCourier/",
  FILTER_HISTORY_EMPLOYEES: "/FilterHistoricalOrgEmployee/",
  CHANGE_COURIER_PASSWORD: "/ChangeCourierPassword",
  REACTIVATE_EMPLOYEE: "/HistoricalOrgEmployee"
};

const INVENTORY_API = {
  ROOT: "/inventory/inventory_orders",
  COMPLETE_ORDER: "complete-order/",
  CANCEL_ORDER: "cancel-order/",
  ACCEPT_SELLER_ORDER: "accept-seller-create/",
  ACTIVATE_SWITCH: "/inventory/active/",
  INVENTORY_ITEMS: "/inventory/inventory_items",
  AVAILABLE_INVENTORY_ITEM_INVENTORIES: "annotations_for_order_creation",
  INVENTORIES: "/inventory/inventories",
  INVENTORIES_SUMMARY: "/inventory/inventories/summary",
  ACCEPT_INVENTORY: "/inventory/inventory_orders/accept-orders/",
  REJECT_INVENTORY: "/inventory/inventory_orders/reject-orders/"
};

const WAREHOUSES_API = {
  ROOT: "/inventory/warehouses",
  ALL_LOCATIONS: "all-locations/",
  LOCATIONS: "locations",
  ADD_LOCATION: "locations/add-location/",
  UPDATE_LOCATION: "locations/update-location",
  ACTIVATE_LOCATION: "locations/activate-location",
  DEACTIVATE_LOCATION: "locations/deactivate-location",
  DELETE_LOCATION: "locations/delete-location",
  INVENTORY_ROLES: "inventory-adding-rules",
  ADD_INVENTORY_ROLE: "inventory-adding-rules/add-inventory-adding-rule",
  UPDATE_INVENTORY_ROLE: "inventory-adding-rules/update-inventory-adding-rule",
  DELETE_INVENTORY_ROLE: "inventory-adding-rules/delete-inventory-adding-rule",
  INVENTORIES: "inventories/",
  INVENTORIES_SUMMARY: "inventories/summary/"
};

const CLIENTS_API = {
  ROOT: "/SavedClient",
  FILTER: "/FilterClients/",
  CLIENT_ADDRESS: "/ClientAddress"
};

const ORDERS_API = {
  ROOT: "/Order",
  ORDER_CHAIN_SUMMARY: "/OrderChainSummary",
  BULK_ORDER: "BulkOrder/",
  SELLER_ORDERS: "/SellerOrders",
  ARCHIVED: "/archived",
  RETURN_ORDER: "/ReturnedOrder/",
  CANCEL_CONFIRMED_ORDER: "/CancelConfirmedOrder/",
  UNAUTHED_TRACKING: "/track-order",
  ACCEPT_FROM_STOCK: "/AcceptFromStockOrder",
  REPLACE_ORDER: "/ReplacementOrder",
  FILTER: "/FilterOrder/",
  ACCEPT_ORDER: "/AcceptOrders/",
  POSTPONED_ORDER: "/PosponedOrders/",
  CHANGE_ORDER_TYPE: "/ChangeOrderType/"
};

const TICKETS_API = {
  ROOT: "/Ticket",
  FILTER: "/FilterTicket/",
  FILTER_HISTORY: "/FilterHistoricalTickets/",
  MOVE_TICKET: "/MoveTicket/state",
  CANCEL_CONFIRMED: "/CancelConfirmedOrder/"
};

const SMS_TEMPLATES_API = {
  ROOT: "/sms-templates",
  ACTIVE_SWITCH: "activate-switch/"
};

const REPORTS_API = {
  BRANCH_REPORT: "/BranchReport/",
  COURIER_REPORT: "/CourierReport/",
  FINANCIAL_REPORT: "/FinancialReport/",
  SELLER_REPORT: "/SellerReport/",
  ROOT: "/Report/",
  ORDER_DETAILS: "/OrderDetailsReport/"
};

const SETTINGS_API = {};

const RUNSHEETS_API = {
  ROOT: "/Runsheet",
  FILTER: "/FilterRunsheet/",
  ADD_TO_RUNSHEET: "/AddToRunsheet",
  COURIER_ACTIVE_SHEET: "/ActiveRunsheet",
  HUB_RECEIVE: "/ReceiveFromCourier",
  REASSIGN_COURIER: "/ReassigneCourier",
  UNASSIGN_COURIER: "/UnassignRunsheet",
  COURIER_SETTLEMENT: "/ReceiveMoneyFromCourier",
  FORCE_ACCEPT: "/ForceRunsheet/",
  REMOVE_FROM_RUNSHEET: "/RemoveFromRunsheet",
  CLOSE_RUNSHEET: "/CloseRunsheet"

};

const INTERNAL_RUNSHEETS_API = {
  ROOT: "/InternalRunsheet",
  FILTER: "/FilterInternalRunsheet/",
  STATE: "/InternalRunsheetState/",
  AVAILABLE_ORDERS: "/OrdersForInternalRunsheet/",
  ADD_TO_RUNSHEET: "/AddToInternalRunsheet/",
  REMOVE_FROM_RUNSHEET: "/RemoveFromInternalRunsheet/",
  REASSIGN_RUNSHEET: "/ReassignInternalRunsheet/"
};

const Branches_API = {
  ROOT: "/Branch"

};

const TREECODE_API = {
  GET_PERMISSIONS: "/permissions",
  ADD_PERMISSION: "/add_permissions",
  REMOVE_PERMISSION: "/remove_permissions",
  TIERS: "/Tier",
  DOWNLOAD_LABEL: "/CreateInvoice/",
  PLAN_CONSUMPTION: "/PlanConsumption/",
  COURIER_WALLET_SWITCH: "/courier/wallet/active/",
  AWB_TYPE: "/awb/type/",
  CANCELLATION_REASON_REQUIRED_SWITCH: "/cancellation/reason/required/",
  RETURN_REASON_REQUIRED_SWITCH: "/return/reason/required/",
  RETURN_REASON_ACTIVATE_SWITCH: "/return/reason/active/"

};

const ORDER_TYPES_API = {
  ROOT: "/order-types",
  CHANGE_ORDER_TYPE: "/AcceptWithChangeOrderType/"
};

const PRODUCTS_API = {
  ROOT: "/SavedProduct",
  FILTER: "/FilterProducts/"
};

const SELLERS_API = {
  ROOT: "/Seller",
  FILTER: "/FilterSeller/",
  CREATE_3RD_PARTY: "/CreateThirdPartyForSeller",
  SELLER_ADDRESS: "/SellerAddress",
  SELLER_WALLETS: "/OrderChainWallet/",
  FILTER_SELLER_ADDRESS: "/FilterSellerAddress/"
};

const CITIES_API = {
  ROOT: "/City",
  FILTER: "/FilterCity/"
};

const COUNTRIES_API = {
  ROOT: "/Country"
};

const AREAS_API = {
  ROOT: "/Area",
  FILTER: "/FilterAreas/"
};

const STATES_API = {
  ROOT: "/State",
  FILTER: "/FilterState/"
};

const PRODUCT_CATEGORIES_API = {
  ROOT: "/ProductCategory",
  FILTER: "/FilterProductCategory/"
};

const DASHBOARD_API = {
  SUMMARY: "/Summary/"
};

const ORGANIZATION_API = {
  ROOT: "/Organization/",
  CHANGE_EMAIL_REQUEST: "/change_email",
  CHANGE_EMAIL_VERIFY: "/change_email_code"
};

const THIRD_PARTY_API = {
  ROOT: "/ThirdPartyCompany",
  MY_THIRD_PARTY: "/ThirdPartyCompany/me",
  REGENERATE_KEYS: "//RegenrateKeys",
  MY_WEBHOOKS: "/Webhook/me",
  FILTER: "/FilterThirdPartyCompany/"
};

const PAYMENT_REQUESTS_API = {
  ROOT: "/PaymentRequest",
  FILTER: "/FilterPaymentRequest/",
  ACCEPT: "/AcceptPaymentRequest",
  SUMMARY: "/PaymentSummary/",
  PROOF: "/UploadProof",
  SELLER_CONFIRM: "/SellerConfirmPayment"

};

const PUBLIC_SCHEMA_API = {
  REGISTER: "/client_create",
  PLUGINS_ROOT: "/plugins"
};

const SUBSCRIPTIONS_API = {
  ROOT: "/subscriptions-client",
  PLANS_ROOT: "/plans-client",
  MY_SUBSCRIPTION: "my-subscription/",
  SUBSCRIBE: "subscribe",
  RENEW: "renew-my-subscription",
  CANCEL: "cancel-my-subscription",
  VALIDATE: "validate-transaction"
};

const COMMENT_API = {
  ROOT: "/Comments"
};

const COURIER_WALLET_API = {
  ROOT: "/CourierWallet"

};

export {
  BaseUrl,
  BasePublicUrl,
  AUTH_API,
  ACCOUNTS_API,
  INVENTORY_API,
  ORDERS_API,
  TICKETS_API,
  REPORTS_API,
  SETTINGS_API,
  RUNSHEETS_API,
  INTERNAL_RUNSHEETS_API,
  TREECODE_API,
  SMS_TEMPLATES_API,
  WAREHOUSES_API,
  CLIENTS_API,
  Branches_API,
  SELLERS_API,
  PRODUCTS_API,
  CITIES_API,
  COUNTRIES_API,
  AREAS_API,
  STATES_API,
  PRODUCT_CATEGORIES_API,
  DASHBOARD_API,
  ORGANIZATION_API,
  THIRD_PARTY_API,
  PAYMENT_REQUESTS_API,
  PUBLIC_SCHEMA_API,
  SUBSCRIPTIONS_API,
  LOGIN,
  CLEAR_AUTH,
  COMMENT_API,
  ORDER_TYPES_API,
  COURIER_WALLET_API
};
