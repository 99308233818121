import { createApi } from "@reduxjs/toolkit/query/react";
import { ORDERS_API } from "../constants";
import { reAuthBaseQuery } from "./baseQuery";
import downloadHandler from "./downloadHandler";

export const ordersApi = createApi({
  reducerPath: "ordersApi",
  baseQuery: reAuthBaseQuery,
  tagTypes: ["Orders"],
  endpoints: (builder) => ({
    getOrders: builder.mutation({
      query: () => ({
        url: ORDERS_API.ROOT + "/",
        method: "GET"
      }),
      invalidatesTags: ["Orders"]
    }),
    getOrder: builder.mutation({
      query: (orderId, { page = 1, limit = 10, ...queryParameters } = {}) => ({
        url: `${ORDERS_API.ROOT}/${orderId}`,
        method: "GET",
        params: {
          page,
          page_size: limit,
          ...queryParameters
        }
      }),
      providesTags: ["Orders"]
    }),
    createOrder: builder.mutation({
      query: (body) => ({
        url: ORDERS_API.ROOT + "/",
        method: "POST",
        body
      }),
      invalidatesTags: ["Orders"]
    }),
    updateOrder: builder.mutation({
      query: ({ orderId, ...body }) => ({
        url: `${ORDERS_API.ROOT}/${orderId}`,
        method: "PUT",
        body
      }),
      invalidatesTags: ["Orders"]
    }),
    deleteOrder: builder.mutation({
      query: (orderId) => ({
        url: `${ORDERS_API.ROOT}/${orderId}`,
        method: "DELETE"
      }),
      invalidatesTags: ["Orders"]
    }),
    filterOrders: builder.mutation({
      query: ({ page = 1, limit = 10, ...queryParameters }) => ({
        url: ORDERS_API.FILTER,
        method: "GET",
        params: {
          page,
          page_size: limit,
          ...queryParameters
        }
      }),
      invalidatesTags: ["Orders"]
    }),
    getOrderChainSummary: builder.mutation({
      query: (orderChainId) => ({
        url: `${ORDERS_API.ORDER_CHAIN_SUMMARY}/${orderChainId}`,
        method: "GET"
      }),
      providesTags: ["Orders"]
    }),
    bulkCreateOrders: builder.mutation({
      query: (file) => {
        const formData = new FormData();
        formData.append("file", file);
        return {
          url: ORDERS_API.BULK_ORDER,
          method: "POST",
          body: formData
        };
      }
    }),

    getBulkOrdersTemplate: builder.mutation({
      query: () => ({
        url: ORDERS_API.BULK_ORDER, // Use this as the correct endpoint
        method: "GET",
        responseHandler: async (response) => await downloadHandler(response),
        cache: "no-cache"
      })
    }),

    getSellerOrders: builder.mutation({
      query: (sellerId, queryParameters) => ({
        url: `${ORDERS_API.SELLER_ORDERS}/${sellerId}`,
        method: "GET",
        params: queryParameters
      }),
      invalidatesTags: ["Orders"]
    }),

    getArchivedOrders: builder.mutation({
      query: ({ page = 1, limit = 10, ...queryParameters }) => ({
        url: ORDERS_API.ARCHIVED + "/",
        method: "GET",
        params: {
          page,
          page_size: limit,
          ...queryParameters
        }
      }),
      invalidatesTags: ["Orders"]
    }),
    getArchivedOrder: builder.mutation({
      query: (orderId, { page = 1, limit = 10, ...queryParameters }) => ({
        url: `${ORDERS_API.ARCHIVED}/${orderId}`,
        method: "GET",
        params: {
          page,
          page_size: limit,
          ...queryParameters
        }
      }),
      providesTags: ["Orders"]
    }),
    deleteArchivedOrder: builder.mutation({
      query: (orderId) => ({
        url: `${ORDERS_API.ARCHIVED}/${orderId}`,
        method: "DELETE"
      }),
      invalidatesTags: ["Orders"]
    }),
    createArchivedOrder: builder.mutation({
      query: (body) => ({
        url: ORDERS_API.ARCHIVED + "/",
        method: "POST",
        body
      }),
      invalidatesTags: ["Orders"]
    }),
    exportOrdersSheet: builder.mutation({
      query: (queryParameters) => ({
        url: ORDERS_API.FILTER,
        method: "GET",
        params: queryParameters,
        responseHandler: async (response) => await downloadHandler(response),
        cache: "no-cache"
      }),
      invalidatesTags: ["Orders"]
    }),
    createReturnOrder: builder.mutation({
      query: (body) => ({
        url: ORDERS_API.RETURN_ORDER,
        method: "POST",
        body
      }),
      invalidatesTags: ["Orders"]
    }),
    cancelConfirmOrder: builder.mutation({
      query: (body) => ({
        url: ORDERS_API.CANCEL_CONFIRMED_ORDER,
        method: "POST",
        body
      }),
      invalidatesTags: ["Orders"]
    }),
    unAuthedTrackOrder: builder.mutation({
      query: (waybill) => ({
        url: `${ORDERS_API.UNAUTHED_TRACKING}/${waybill}/`,
        method: "GET"
      }),
      providesTags: ["Orders"]
    }),
    acceptFromStock: builder.mutation({
      query: ({ orderId, ...body }) => ({
        url: `${ORDERS_API.ACCEPT_FROM_STOCK}/${orderId}`,
        method: "POST",
        body
      }),
      invalidatesTags: ["Orders"]
    }),
    createReplacementOrder: builder.mutation({
      query: (body) => ({
        url: ORDERS_API.REPLACE_ORDER + "/",
        method: "POST",
        body
      }),
      invalidatesTags: ["Orders"]
    }),
    updateReplacementOrder: builder.mutation({
      query: ({ orderId, ...body }) => ({
        url: `${ORDERS_API.REPLACE_ORDER}/${orderId}`,
        method: "PUT",
        body
      }),
      invalidatesTags: ["Orders"]
    }),
    acceptOrRejectOrders: builder.mutation({
      query: (body) => ({
        url: ORDERS_API.ACCEPT_ORDER,
        method: "POST",
        body
      }),
      invalidatesTags: ["Orders"]
    }),
    postponedOrder: builder.mutation({
      query: (body) => ({
        url: ORDERS_API.POSTPONED_ORDER,
        method: "POST",
        body
      }),
      invalidatesTags: ["Orders"]
    }),
    changeOrdersType: builder.mutation({
      query: (body) => ({
        url: ORDERS_API.CHANGE_ORDER_TYPE,
        method: "POST",
        body
      }),
      invalidatesTags: ["Orders"]
    })
  })
});

export const {
  useGetOrdersMutation,
  useGetOrderMutation,
  useCreateOrderMutation,
  useUpdateOrderMutation,
  useDeleteOrderMutation,
  useFilterOrdersMutation,
  useGetOrderChainSummaryMutation,
  useBulkCreateOrdersMutation,
  useGetBulkOrdersTemplateMutation,
  useGetSellerOrdersMutation,
  useGetArchivedOrdersMutation,
  useGetArchivedOrderMutation,
  useDeleteArchivedOrderMutation,
  useCreateArchivedOrderMutation,
  useExportOrdersSheetMutation,
  useCreateReturnOrderMutation,
  useCancelConfirmOrderMutation,
  useUnAuthedTrackOrderMutation,
  useAcceptFromStockMutation,
  useCreateReplacementOrderMutation,
  useUpdateReplacementOrderMutation,
  useAcceptOrRejectOrdersMutation,
  usePostponedOrderMutation,
  useChangeOrdersTypeMutation
} = ordersApi;
